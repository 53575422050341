import { MutableRefObject, useEffect, useRef } from 'react';

export type RefType<U> = MutableRefObject<U & Element>;

export default function useLazyCallback<T extends HTMLElement>(
  callback: (ref: RefType<T>) => void,
  options?: IntersectionObserverInit,
) {
  const ref = useRef<T>(null) as RefType<T>;

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(element);
          callback(ref);
        }
      });
    }, options);

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [callback, options]);

  return ref;
}
