import { tw } from '@/utils/tw';

const Tag = tw.theme({
  base: ['block', 'cursor-pointer', 'whitespace-nowrap', 'text-headline-xs'],
  variants: {
    colors: {
      default: [
        'bg-primary-800',
        'ring-primary-500/20',
        'border-transparent',
        'text-white',
        'hover:bg-gray-200',
        'hover:text-black',
        'focus:bg-gray-300',
        'active:bg-gray-300',
      ],
      text: ['text-black', 'ring-transparent'],
    },
    size: {
      small: ['px-3', 'py-1.5'],
      medium: ['px-3', 'py-2.5'],
      large: ['px-6', 'py-4'],
      text: [],
    },
    variant: {
      default: [
        'rounded-full',
        'outline-none',
        'ring-0',
        'transition-all',
        'duration-200',
        'ease-in-out',
        'hover:ring-0',
        'focus:ring-4',
        'active:ring-0',
      ],
      text: ['hover:underline'],
    },
  },
  defaultVariants: {
    colors: 'default',
    size: 'medium',
    variant: 'default',
  },
});

export default Tag;
