import { Tag, TagProps } from '@/components/Tag';
import { componentClassName, useTheme } from '@/styles/JwVideoTeaser';
import { StandaloneComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';

export type VideoTeaserContentTagProps = TagProps;

export const VideoTeaserContentTagComponent: StandaloneComponent<VideoTeaserContentTagProps> = (props) => {
  const className = componentClassName(
    '',
    { variant: 'text', size: 'text', colors: 'text' },
    { theme: useTheme('tag') },
  );

  return (
    <Tag
      {...mergeProps(
        {
          options: {
            className,
            variant: 'text',
            size: 'text',
            colors: 'text',
          },
        },
        props,
      )}
    />
  );
};
